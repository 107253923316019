import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M14 3.987a68 68 0 0 0-6.68-.334q-1.98 0-3.96.2L2 3.987M5.666 3.313l.147-.873c.106-.633.186-1.107 1.313-1.107h1.747c1.126 0 1.213.5 1.313 1.114l.147.866M12.567 6.093l-.433 6.714c-.074 1.046-.134 1.86-1.994 1.86H5.86c-1.86 0-1.92-.814-1.993-1.86l-.433-6.714M6.887 11h2.22M6.334 8.333h3.333"
    }, null, -1)
  ])))
}
export default { render: render }